<template>
  <el-modal title="Enter Track N Trace" :visible="showModal" @close="$emit('close')">
    <div>
      <div>
        <p class="mt-4 text-grey-300">Insert track n trace number if Avisfordele should notify the customer</p>
      </div>

      <div class="sm:flex sm:items-center mt-20">
        <el-input
          v-model="trackingNumber"
          input-class="rounded w-full"
          placeholder="Ex. 9827349237525"
          :disabled="buttonLoading"
          class="max-w-320 w-full"
        />
        <el-button type="primary" class="text-base sm:text-sm mt-12 sm:mt-0 sm:ml-12" :loading="buttonLoading" @click="onFulfillOrder(trackingNumber, notify)">Submit</el-button>
      </div>

      <div class="flex">
        <el-checkbox v-model="notify" :disabled="trackingNumber === ''" class="mt-16">Do not notify customer</el-checkbox>
      </div>
    </div>
  </el-modal>
</template>

<script>

const ElModal = () => import('@/components/shared/ELModal')
const ElInput = () => import('@/components/shared/ELInput')
const ElButton = () => import('@/components/shared/ELButton')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'FulfillOrderModal',

  components: { ElModal, ElInput, ElButton, ElCheckbox },

  props: {
    onFulfillOrder: {
      type: Function,
      required: true
    },

    showModal: {
      type: Boolean,
      default: false
    },

    buttonLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      trackingNumber: '',
      notify: false
    }
  },

  watch: {
    trackingNumber (newVal) {
      if (newVal === '') {
        this.notify = false
      }
    }
  }
}
</script>
